.middle-splash {
  width: 100%;
  height: 100vh;
  background: #9240BE;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadein 0.6s;
  font-size: 17px;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoominoutsinglefeatured {
    0% {
      opacity: 0;
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
        opacity: 1;
    }
}

.middle-splash__button {
  padding: 14px 40px;
  width: 100%;
  display: block;
  background: #fff;
  color: #9240BE;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
}

.middle-splash__inner {
  width: 300px;
  max-width: 300px;
  text-align: center;
  opacity: 0;
  animation: zoominoutsinglefeatured .3s ease-in forwards;
  animation-delay: .5s;
}

.middle-splash__icon {
  width: 100px;
  height: 100px;
}


.middle-splash__title {
  margin-top: 5px;
}