
.registration-wrapper {
  min-height: 100vh;
  padding: 40px 0;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadein 0.6s;
  font-size: 14px;
}

.registration-form__container {
  width: 300px;
  max-width: 300px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
  0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.registration-wrapper .registration-form__container .field {
  text-align: left;
  margin: 5px 0 15px!important;
}

.registration-wrapper .registration-form__container .registration-form__submit,
.registration-wrapper .registration-form__container .registration-form__next {
  padding: 14px 40px;
  width: 100%;
  display: block;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  background: rgb(0, 201, 200)!important;
}