* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  position: fixed;
  overflow: hidden;
}

.swiper-wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png')
      39 39,
    auto;
}

.swiper-wrapper  .card__wrapper-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper  div.card__wrapper-wrapper  div.card__wrapper {
  padding: 5px;
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 300px;
  max-width: 300px;
  height: 500px;
  max-height: 550px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

img {
  height: 380px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

button[aria-label='previous'],
button[aria-label='next'] {
  background: salmon !important;
  color: black !important;
}

h2 {
  display: inline-block;
  margin-right: 10px;
}

h2:nth-of-type(2) {
  opacity: 0.7;
  font-size: 1.35rem;
}

h5 {
  margin-top: -15px;
  margin-left: 2px;
  color: lightgray;
}
h5:last-of-type {
  color: darkgray;
}

.card > div {
  height: 380px !important;
}


.card > img {
  width: 100%;
  height: auto;
  margin-top: 20px; 
}

.card h2 {
  font-size: 16px;
}

.card {
  height: 500px !important;
  text-align: center;
  padding: 20px;
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  z-index: 9999;
  animation: fadein .3s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.splash__result {
  display: flex;
}

.splash__card {
  width: 300px;
  max-width: 300px;
  text-align: left;
}

.splash__result > img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.splash__result > p {
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.splash__next {
  padding: 14px 40px;
  background: #fff;
  border: 0;
  color: #06c6cb;
  font-size: 16px;
  border-radius: 10px;
  font-weight: bold;
  width: 100%;
  margin-top: 20px;
}

.swiper__left img,
.swiper__right img {
  width: 60px;
  height: 60px;
}

.swiper__left,
.swiper__right {
  position: absolute;
  bottom: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: .75;
}

.swiper__left {
  left: 40px;
}

.swiper__right {
  right: 40px;
}

.splash__wrong {
  background: rgba(210, 64, 39, .94);
}

.splash__wrong .splash__next{
  color: rgba(210, 64, 39, 1);
}
.splash__correct {
  background: rgba(0, 201, 200, .94);
}

.slider-control-bottomcenter {
  bottom: -15px !important;
}

.slider-control-bottomcenter > ul > li > button {
  color: salmon !important;
}
