.k-message-box {
  display: none;
}

.k-bubble {
  white-space: pre-wrap;
}

.k-widget {
  height: 100vh;
}
