.Home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffbf60;
  flex-direction: column;
}

.Home__lottie {
  width: 100%;
  height: 100%;
}

.Home__button {
  animation: zoominoutsinglefeatured 0.3s ease-in forwards;
  padding: 14px 40px;
  display: block;
  background: #fff;
  color: #9240be;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
}
